import React, { useState } from "react";

import { Grid, FormControlLabel, Checkbox, Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import "./DynamicInfo.css";

const DynamicInfo = ({
    infos, handleDynamicInfoCheckboxes, 
    isSurveyRequired=false, isSurveyInfoPresent=false 
}) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    if(infos.length === 0){
        handleDynamicInfoCheckboxes({
            isSurveyRequired: undefined,
            isSurveyInfoPresent: undefined
        })
        return <></>
    }

    const dialogInfos = infos.filter(
        info => (info.source === "user") || (info.source === "bot")
    ).slice(0, 4);
    const questionnaireInfos = infos.filter(
        info => !((info.source === "user") || (info.source === "bot"))
    ).slice(0, 5);

    return (
        <div>
            <div className="title-container">
                <h1 onClick={handleToggle}>
                    Relevant information from Survey
                </h1>
                <IconButton onClick={handleToggle}>
                    {!open ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
            </div>
            <Collapse in={open}>
                <div className="isRelInfoRequired-container">
                    <Grid container columnSpacing={0} rowSpacing={0}>
                        <Grid item xs={12} md={12} sm={12}>
                            <FormControlLabel
                                value="isRequired"
                                control={
                                    <Checkbox
                                        checked={isSurveyRequired}
                                        onChange={(e) => handleDynamicInfoCheckboxes({
                                            isSurveyRequired: e.target.checked,
                                            isSurveyInfoPresent: (e.target.checked === true) ? false : undefined
                                        })}
                                    />
                                }
                                label="Is the RAG required to answer the question?"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <FormControlLabel
                                disabled={!isSurveyRequired}
                                value="isSurveyInfoPresent"
                                control={
                                    <Checkbox
                                        checked={isSurveyInfoPresent}
                                        onChange={(e) => handleDynamicInfoCheckboxes({isSurveyInfoPresent: e.target.checked})}
                                    />
                                }
                                label="Was the RAG successful?"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                </div>
                <div 
                    className="dynamic-info-container"
                >
                    <Grid container columnSpacing={0} rowSpacing={0}>
                        <Grid item md={6} xs={6} sm={6}>
                            <div className='dynamic-info-item dynamic-info-left dynamic-info-heading'><p>Utterance</p></div> 
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <div className='dynamic-info-item dynamic-info-heading'><p>Response</p></div>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <div className='dynamic-info-item dynamic-info-left dynamic-info-heading'><p>Questionnaire Information</p></div>
                        </Grid>
                        { questionnaireInfos.map((info) => {
                            return (<>
                                <Grid item md={6} xs={6} sm={6}>
                                    <div className='dynamic-info-item dynamic-info-left dynamic-info-text'>
                                        <p>{info.question}</p>
                                    </div> 
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <div className='dynamic-info-item dynamic-info-text'>
                                        <p>{info.answer}</p>
                                    </div>
                                </Grid>
                            </>)
                        })}
                        <Grid item md={12} xs={12} sm={12}>
                            <div className='dynamic-info-item dynamic-info-left dynamic-info-heading'><p>Dialogue Information</p></div>
                        </Grid>
                        { dialogInfos.map((info, index) => {
                            return (<>
                                <Grid item md={6} xs={6} sm={6}>
                                    <div className='dynamic-info-item dynamic-info-left dynamic-info-text'>
                                        <p>{info.question}</p>
                                    </div> 
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <div className='dynamic-info-item dynamic-info-text'>
                                        <p>{info.answer}</p>
                                    </div>
                                </Grid>
                            </>)
                        })}
                    </Grid>
                </div>
            </Collapse>
        </div>
    )
}
export default DynamicInfo;
