import './dialogBox.css';
import React from 'react';

import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';

export default function DialogBox({alertMessage, unsetAlertMessage, handleYes}){
    return (
        <Dialog
            open={alertMessage !== undefined}
            // onClose={() => unsetAlertMessage()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {alertMessage?.message}
            </DialogTitle>
            <DialogActions>
              {(alertMessage?.type === 'warn') ?
                <Button onClick={unsetAlertMessage}>Close</Button>
                : <>
                  <Button onClick={() => {
                    unsetAlertMessage();
                    handleYes();
                  }}>Yes</Button>
                  <Button onClick={unsetAlertMessage}>No</Button>
                </>
              }
            </DialogActions>
        </Dialog>
    )
}
