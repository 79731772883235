import './Hypotheses.css';
import React, {useState, useEffect} from 'react';
import {Container, Draggable} from 'react-smooth-dnd';
import {
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
} from '@mui/material';

import DialogBox from './auxComponents/dialogBox';

const applyDrag = (arr, dragResult) => {
  const {removedIndex, addedIndex, payload} = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const moveTrashDown = (arr, index, e, very_bad_or_hallucination) => {
  let result = [...arr];
  let lastElem = result[index];

  const is_hallucination = very_bad_or_hallucination === "hallucination";
  const is_very_bad_answer = very_bad_or_hallucination === "very_bad";
  if (
    (lastElem.hypothesis.very_bad_answer === false && is_very_bad_answer) ||
    (lastElem.hypothesis.is_hallucination === false && is_hallucination)
  ) {
    if (is_very_bad_answer) {
      lastElem.hypothesis.very_bad_answer = true;
    } else if (is_hallucination) {
      lastElem.hypothesis.is_hallucination = true;
    }
    result.splice(index, 1);

    // let lastTrashIndex = result.findIndex((p) => p.hypothesis.very_bad_answer);
    let lastHallucinationIndex = result.findIndex((p) => p.hypothesis.is_hallucination);
    if (lastHallucinationIndex !== -1 && is_very_bad_answer) {
      result = [...result.slice(0, lastHallucinationIndex), lastElem, ...result.slice(lastHallucinationIndex)]
    } else {
      result.push(lastElem);
    }

  } else {
    if (is_very_bad_answer) {
      lastElem.hypothesis.very_bad_answer = false;
    } else if (is_hallucination) {
      lastElem.hypothesis.is_hallucination = false;
    }
    result.splice(index, 1);

    // if is_very_bad_answer
    let topIndex = Math.max(0, result.findIndex((p) => p.hypothesis.very_bad_answer))
    if (topIndex === 0) {
      topIndex = Math.max(0, result.findIndex((p) => p.hypothesis.is_hallucination));
      if (topIndex === 0) {
        topIndex = result.length
      }
    }
    result = [...result.slice(0, topIndex), lastElem, ...result.slice(topIndex)];
  }
  return result;
}

const TASK_DESCR = {
  'sort': (<><h1>Sort the hypotheses from top to bottom</h1>
    <h3>(the top one corresponds to the best hypothesis)</h3></>),
  'good_threshold': <h1>Click between hypotheses to select the boundary of good and bad
    hypotheses.</h1>,
  'is_dialog_good': <h1>Is this dialogue meaningless?</h1>,
  'write_best_answer': <h1>Write the answer to the last question that you think would be
    better</h1>,
}

const prepareData = (items, dialogGood, best_answer, skipped) => {
  let counter = 0;
  items.map((hypothesis) => {
    if (!(hypothesis.hypothesis.very_bad_answer) && !(hypothesis.hypothesis.is_hallucination)) {
      counter++;
    }
    hypothesis.hypothesis.best_answer = best_answer
    if (hypothesis.hypothesis.very_bad_answer) {
      hypothesis.hypothesis.label = -1
    } else if (hypothesis.hypothesis.is_hallucination) {
      hypothesis.hypothesis.label = -2
    } else {
      hypothesis.hypothesis.label = items.length - counter
    }
  });
  const labels = items.map(hypothesis => hypothesis.hypothesis)
  // const indices = items.map(p => p.index);
  // Uncomment for enabling good threshold chosing.
  // const thresholdIndex = items.findIndex(p => p.barClicked) + 1;
  // const is_good = items.map((_, i) => i < thresholdIndex);
  // const is_good = undefined;
  //
  // let isDialogBad = undefined; // in case of dialogGood === 'none'
  // if (dialogGood === 'good') {
  //   isDialogBad = false;
  // } else if (dialogGood === 'bad') {
  //   isDialogBad = true;
  // }

  return {labels, skipped};
}

const handleBackButton = (task, setTask, handleToPreviousQuestion) => {
  if (task === 'write_best_answer') {
    setTask('sort');
    return
  }
  handleToPreviousQuestion()
}

export default function Hypotheses({
                                     labelingLast,
                                     hypotheses = [],
                                     oldBestAnswer,
                                     handleSendData,
                                     handleToPreviousQuestion,
                                     showSkip,
                                   }) {
  const [items, setItems] = useState([]);
  const [task, setTask] = useState('sort');
  const [dialogGood, setDialogGood] = useState(undefined);
  const [alertMessage, setAlertMessage] = useState(undefined);
  const [unchanged, setUnchanged] = useState(true);
  const [bestAnswer, setBestAnswer] = useState(oldBestAnswer);
  let skip = showSkip;

  function handleClick(task, setTask, handleSendData, items, labelingLast, dialogGood, setDialogGood, bestAnswer, setBestAnswer) {
    // manages warning message for unsorted hypotheses case
    if ((task === 'sort') && unchanged) {
      if (items.length > 1) {
        setAlertMessage({
          type: 'dialog',
          message: "You haven't sorted hypotheses. Are you sure?"
        })
        return
      }
      ;
      setUnchanged(false);
    }
    // Uncomment to enable good threshold chosing.
    // if (task === 'sort'){
    //   setTask('good_threshold');
    //   return
    // }
    // if ((task === 'good_threshold') & labelingLast){
    // if ((task === 'sort') & labelingLast){ // Comment for enabling good threshold chosing.
    //   setTask('is_dialog_good');
    //   toggleLastHistoryUtterance();
    //   return
    // }
    // if((dialogGood === undefined) && (task === 'is_dialog_good')){
    //   setAlertMessage({
    //     type: 'warn',
    //     message: "Check one of the options"
    //   });
    //   return
    // }
    // if(task === 'sort'){
    //   setTask('write_best_answer');
    //   return
    // }
    // if ((task === 'write_best_answer') && (bestAnswer === undefined)){
    //   setAlertMessage({
    //     type: 'warn',
    //     message: "Write your answer to the last question"
    //   });
    //   setUnchanged(false);
    //   return
    // }

    // reset values
    // if (task === 'is_dialog_good'){
    //   toggleLastHistoryUtterance();
    // }
    handleSendData(prepareData(items, dialogGood, bestAnswer, task === "skip"));

    setTask('sort');
    setDialogGood('none');
    setUnchanged(true);
    setBestAnswer(undefined);
  }

  function isAnyClicked() {
    return items.some(p => p.barClicked);
  }

  const handleColorChange = (e, i, green = '#2d8') => {
    if (isAnyClicked() && !items[i].barClicked) {
      animateShake(i);
      return;
    }

    const currColor = e.target.style.backgroundColor;
    const newColor = (currColor === 'rgb(34, 221, 136)') ? null : green;
    e.target.style.backgroundColor = newColor;
    if (e.type === 'click') {
      let newState = [...items]
      newState[i].barClicked = !items[i].barClicked;
      setItems(newState);
    }
  }

  const animateShake = (i) => {
    // Bar begins to shake
    let newItems = [...items];
    newItems[i].shakeBar = true;
    setItems(newItems);

    // Bar stops to shake after 2 seconds
    setTimeout(() => {
      let newItems = [...items];
      newItems[i].shakeBar = false;
      setItems(newItems);
    }, 800);

  };

  useEffect(() => setItems(hypotheses.map((hypothesis, ind) => {
          return {
            hypothesis: hypothesis,
            barClicked: false,
            shakeBar: false,
            is_very_bad_answer: (hypothesis.length < 2) || hypothesis.very_bad_answer,
            is_hallucination: hypothesis.is_hallucination,
          }
        }
      ).sort(
        (left_hypothesis, right_hypothesis) => {
          if (left_hypothesis.hypothesis.label > right_hypothesis.hypothesis.label) return -1;
          if (left_hypothesis.hypothesis.label < right_hypothesis.hypothesis.label) return 1;
          return 0;
        }
      )
    ),
    [hypotheses]
  )

  return (
    <div className='simple-page'>
      <DialogBox
        alertMessage={alertMessage}
        unsetAlertMessage={() => setAlertMessage(undefined)}
        handleYes={() => setUnchanged(false)}
      />
      <div
        className='best-answer'
        // style={{display: (task === 'write_best_answer') ? 'block' : 'none'}}
      >
        {TASK_DESCR['write_best_answer']}
        <FormControl fullWidth>
          <TextField
            required
            label="Your answer"
            variant="standard"
            className='form-input'
            multiline
            rows={4}
            onChange={evt => setBestAnswer(evt.target.value)}
            value={bestAnswer ? bestAnswer : ''}
            margin='normal'
            sx={{fontSize: "1.2em"}}
            fullWidth
          />
        </FormControl>
      </div>
      <div className='row'>
        {TASK_DESCR[task]}
      </div>
      <div className='row flex-container'>
        <div className="hypotheses-container">
          <div
            className='dialog-good'
            style={{display: (task === 'is_dialog_good') ? 'block' : 'none'}}
          >
            <FormControl>
              <RadioGroup
                row
                name="position"
                value={dialogGood}
                onClick={(e) => {
                  setDialogGood(e.target.value);
                  setAlertMessage(undefined);
                }}
              >
                <FormControlLabel
                  value="bad"
                  control={<Radio/>}
                  label="Yes"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="good"
                  control={<Radio/>}
                  label="No"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div
            className={'draggable-bar'}
            style={{
              visibility: (task === 'good_threshold' && !isAnyClicked()) ? 'visible' : 'hidden',
              backgroundColor: '#2d8'
            }}
          ></div>
          <Container
            style={{
              display: (['sort', 'good_threshold'].indexOf(task) !== -1) ? 'block' : 'none',
            }}
            dragHandleSelector={(task === 'sort') ? undefined : 'nondraggable'}
            onDrop={e => setItems(() => {
              setUnchanged(false);
              return applyDrag(items, e);
            })}
            shouldAcceptDrop={(_, payload) => !payload.hypothesis.very_bad_answer}
            getChildPayload={(i) => items[i]}
          >
            {items.map((hypothesis, num) => {
              return (
                <Draggable key={hypothesis ? hypothesis.index : num}>
                  <div className="draggable-item" id={hypothesis.index}>
                    <Grid container spacing={{xs: 0, md: 2, sm: 2}}>
                      <Grid item xs={9} sm={9.5} md={10.5}>
                        <div className="draggable-text">
                          <p>{hypothesis.hypothesis.text}</p></div>
                      </Grid>
                      <Grid item xs={3} sm={2.5} md={1.5}>
                        <div className="draggable-meaningless">
                          <FormControlLabel
                            sx={{margin: 0}}
                            control={<Checkbox
                              onClick={e => {
                                if (!hypothesis.hypothesis.is_hallucination) {
                                  setItems(() => moveTrashDown(items, num, e, "very_bad"))
                                  setUnchanged(false);
                                }
                              }}
                              checked={hypothesis.hypothesis.very_bad_answer}
                              disabled={task !== 'sort'}
                            />}
                            label="Very bad answer"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            sx={{margin: 0}}
                            control={<Checkbox
                              onClick={e => {
                                if (!hypothesis.hypothesis.very_bad_answer) {
                                  setItems(() => moveTrashDown(items, num, e, "hallucination"))
                                  setUnchanged(false);
                                }
                              }}
                              checked={hypothesis.hypothesis.is_hallucination}
                              disabled={task !== 'sort'}
                            />}
                            label="Hallucination"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            sx={{margin: 0}}
                            control={
                              <Checkbox
                                onClick={e => {
                                  setItems(prevItems => {
                                    const newItems = [...prevItems];
                                    newItems[num] = {
                                      ...newItems[num],
                                      hypothesis: {
                                        ...newItems[num].hypothesis,
                                        is_typo: !newItems[num].hypothesis.is_typo
                                      }
                                    };
                                    return newItems;
                                  });
                                  setUnchanged(false);
                                }}
                                checked={hypothesis.hypothesis.is_typo}
                                disabled={task !== 'sort'}
                              />
                            }
                            label="Typo"
                            labelPlacement="top"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    className={'draggable-bar' + ((hypothesis.shakeBar) ? ' shake' : '')}
                    style={{
                      display: ((task === 'good_threshold') && !hypothesis.hypothesis.very_bad_answer) ? 'block' : 'none',
                    }}
                    onClick={(e) => handleColorChange(e, num)}
                  >
                  </div>
                </Draggable>
              );
            })}
          </Container>
        </div>
      </div>
      <div className='row'>
        <Grid
          container
          spacing={{md: 1, sm: 1, xs: 0}}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
          <Grid item xs={1} md={1}>
            <Button
              variant="outlined"
              className='form-button'
              color='primary'
              onClick={() => handleBackButton(task, setTask, handleToPreviousQuestion)}
              sx={{width: "4.5em", height: "2em", fontSize: "1.2em"}}
            >Back</Button>
          </Grid>

          {skip && (
            <>
              <Grid item xs={3} md={1}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  variant="outlined"
                  className='form-button'
                  color='primary'
                  onClick={() => handleClick(
                    "skip", setTask, handleSendData, items, labelingLast, dialogGood, setDialogGood,
                    bestAnswer, setBestAnswer
                  )}
                  sx={{width: "4.5em", height: "2em", fontSize: "1.2em"}}
                >Skip</Button>
              </Grid>
            </>
          )}

          <Grid item xs={3} md={1}></Grid>
          <Grid item xs={2} md={1}>
            <Button
              variant="contained"
              className='form-button'
              color='primary'
              onClick={() => handleClick(
                task, setTask, handleSendData, items, labelingLast, dialogGood, setDialogGood,
                bestAnswer, setBestAnswer
              )}
              sx={{width: "4.5em", height: "2em", fontSize: "1.2em"}}
            >Next</Button>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </div>
    </div>
  );
}
