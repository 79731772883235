// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-container {
  max-width: 60%;
  margin: auto;
  max-height: 500px;
  overflow: scroll;
}

.history-item{
  text-align: center;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(73, 73, 73, 0.125);
  overflow:hidden;
}

.history-role {
    margin-left:2px;
    float: left;
    display: block;
}

.history-role p {
    text-align: center;
    position: relative;
    top: 50%;
}

.history-item p {
    margin: 5px 0;
}

.history-text {
    text-align: left;
    display: block;
    float: left;
}

.history-meaningless {
    text-align: left;
    display: block;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/history/History.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,yCAAyC;EACzC,eAAe;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".history-container {\n  max-width: 60%;\n  margin: auto;\n  max-height: 500px;\n  overflow: scroll;\n}\n\n.history-item{\n  text-align: center;\n  background-color: #fff;\n  outline: 0;\n  border: 1px solid rgba(73, 73, 73, 0.125);\n  overflow:hidden;\n}\n\n.history-role {\n    margin-left:2px;\n    float: left;\n    display: block;\n}\n\n.history-role p {\n    text-align: center;\n    position: relative;\n    top: 50%;\n}\n\n.history-item p {\n    margin: 5px 0;\n}\n\n.history-text {\n    text-align: left;\n    display: block;\n    float: left;\n}\n\n.history-meaningless {\n    text-align: left;\n    display: block;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
