import React, {useEffect, useRef} from 'react';
import {Grid, FormControlLabel, Checkbox} from '@mui/material';
import "./History.css";

const History = ({history, bot_name, isHistoryTrash, handleHistoryCheckbox}) => {
  const historyEndRef = useRef(null);

  const scrollToBottom = () => {
    historyEndRef.current?.scrollIntoView({behavior: "smooth"})
  }

  useEffect(scrollToBottom, [history]);
  return (
    <>
      <div>
        <h1>History</h1>
        <div className="history-container">
          {history.slice(0, -1).map((utterance, ind) => {
            let role;
            if (utterance.speaker === "user") {
              role = "User"
            } else {
              if (bot_name) {
                role = bot_name;
              }
              else {
                role = "Bot"
              }
            }
            const isLast = ind === history.length - 1
            return (
              <div className='history-item' key={ind}>
                <Grid container spacing={{md: 2, sm: 2, xs: 0.2}}>
                  <Grid item xs={3} md={2.25} sm={2.4}>
                    <div className='history-role'><p>{role}</p></div>
                  </Grid>
                  <Grid item></Grid>
                  <Grid item xs={8.8} md={9.3} sm={8.8} sx={{
                    "border-left": "0.2em solid #2d8"
                  }}>
                    <div className='history-text'><p>{utterance.utterance}</p></div>
                  </Grid>
                </Grid>
              </div>
            )
          })}
          <div ref={historyEndRef}/>
        </div>
      </div>
    </>
  )
}

export default History;
