import React, {useState} from "react";

import {Grid, FormControlLabel, Checkbox, Collapse, IconButton} from '@mui/material';
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import "./StaticInfo.css";

const StaticInfo = ({
                       infos,
                     }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  function formatTextWithBoldNumbers(text) {
    return text.split('\n').map((line, lineIndex) => (
      <p key={lineIndex}>
        {line.split(/(\d+)/).map((part, partIndex) => (
          /\d+/.test(part) ? (
            <strong key={partIndex}>{part}</strong>
          ) : (
            <span key={partIndex}>{part}</span>
          )
        ))}
      </p>
    ));
  }

  return (
    <div>
      <div className="title-container">
        <h1 onClick={handleToggle}>
          Relevant static information from Survey
        </h1>
        <IconButton onClick={handleToggle}>
          {!open ? <ExpandMore/> : <ExpandLess/>}
        </IconButton>
      </div>
      <Collapse in={open}>
        <div
          className="static-info-container"
        >
          <Grid container columnSpacing={0} rowSpacing={0}>
            <Grid item md={6} xs={6} sm={6}>
              <div className='static-info-item static-info-left static-info-heading'>
                <p>Utterance</p></div>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <div className='static-info-item static-info-heading'><p>Response</p>
              </div>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <div className='static-info-item static-info-left static-info-heading'>
                <p>Static Information</p></div>
            </Grid>
            {Object.entries(infos).map(([question, answer]) => {
              return (<>
                <Grid item md={6} xs={6} sm={6}>
                  <div
                    className='static-info-item static-info-left static-info-text'>
                    {formatTextWithBoldNumbers(question)}
                  </div>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <div className='static-info-item static-info-text'>
                    {formatTextWithBoldNumbers(answer)}
                  </div>
                </Grid>
              </>)
            })}
          </Grid>
        </div>
      </Collapse>
    </div>
  )
}
export default StaticInfo;
