import './App.css';
import React from 'react';
import axios from 'axios';

import LabelingApp from './components/labelingApp';
import HomePage from './components/homePage';
import { CHECK_USER_URL } from './config.js';

class App extends React.Component {
  state = {
    user: sessionStorage.getItem('user'),
    password: sessionStorage.getItem('password'),
    errorMessage: false,
  };

  handleEnteringUsername(user_name, password) {
    axios
      .post(CHECK_USER_URL, {
        user_name: user_name,
        password: password,
      })
      .then((res) => {
        // Set both user and password in state and sessionStorage after successful login
        this.setState({ user: user_name, password: password });
        sessionStorage.setItem('user', user_name);
        sessionStorage.setItem('password', password);
        this.setState({ errorMessage: undefined }); // Clear any error message
      })
      .catch((e) => {
        // Handle error response
        this.setState({ errorMessage: e.response?.data?.message || 'Login failed' });
      });
  }

  logOut() {
    // Clear session storage and reset state on logout
    sessionStorage.clear();
    this.setState({ user: undefined, password: undefined });
  }

  render() {
    const { user, password, errorMessage } = this.state;
    return user ? (
      <LabelingApp user={user} password={password} handleLogOut={() => this.logOut()} />
    ) : (
      <HomePage
        errorMessage={errorMessage}
        onLoggingIn={(user, password) => this.handleEnteringUsername(user, password)}
      />
    );
  }
}

export default App;