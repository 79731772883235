// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.static-info-container {
  max-width: 60%;
  margin: auto;
}

.title-container {
  max-width: 80%;
  margin: auto;
  display: inline-flex;
}

.static-info-heading{
    border-top: 0.2em solid #2d8;
    font-size: 1.2em;
    font-weight: bold;
    background-color: rgba(34, 34, 34, 0.125) !important;
}

.static-info-item{
  text-align: center;
  background-color: #fff;
  outline: 0;
  border-right: 0.2em solid #2d8;
  border-bottom: 2px solid rgba(34, 34, 34, 0.125);
  overflow: hidden;
  height: 100%;
}
.static-info-left{
    border-left: 0.2em solid #2d8;
}

.static-info-text {
    border-top: 0.2em solid rgba(34, 34, 34, 0.125);
}

.static-info-text p {
    margin:0;
    padding: 0.2em 0;
    word-wrap: break-word;
    text-align: center;
    display: block;
    top:50%;
}

`, "",{"version":3,"sources":["webpack://./src/components/staticInfo/StaticInfo.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,oBAAoB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,iBAAiB;IACjB,oDAAoD;AACxD;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,8BAA8B;EAC9B,gDAAgD;EAChD,gBAAgB;EAChB,YAAY;AACd;AACA;IACI,6BAA6B;AACjC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,QAAQ;IACR,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;IACd,OAAO;AACX","sourcesContent":[".static-info-container {\n  max-width: 60%;\n  margin: auto;\n}\n\n.title-container {\n  max-width: 80%;\n  margin: auto;\n  display: inline-flex;\n}\n\n.static-info-heading{\n    border-top: 0.2em solid #2d8;\n    font-size: 1.2em;\n    font-weight: bold;\n    background-color: rgba(34, 34, 34, 0.125) !important;\n}\n\n.static-info-item{\n  text-align: center;\n  background-color: #fff;\n  outline: 0;\n  border-right: 0.2em solid #2d8;\n  border-bottom: 2px solid rgba(34, 34, 34, 0.125);\n  overflow: hidden;\n  height: 100%;\n}\n.static-info-left{\n    border-left: 0.2em solid #2d8;\n}\n\n.static-info-text {\n    border-top: 0.2em solid rgba(34, 34, 34, 0.125);\n}\n\n.static-info-text p {\n    margin:0;\n    padding: 0.2em 0;\n    word-wrap: break-word;\n    text-align: center;\n    display: block;\n    top:50%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
