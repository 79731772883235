// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labelingApp {
  text-align: center;
  margin-top:20px;
}

.logout-container{
  position: fixed;
  top:20px;
  /* left: 93%; */
  right: 3%;
}

.progress-bar-container {
  margin: auto;
  width: 35%;
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: right;
  position: fixed;
  right: 2%;
  top: 15%;
}

@media screen and (max-width: 768px) and (min-width: 513px) {
  .progress-bar-container{
    width: 60%;
  }
}

@media screen and (max-width: 512px){
  .progress-bar-container{
    top: 10%;
    width: 40%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/labelingApp/labelingApp.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,eAAe;EACf,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,qBAAqB;EACrB,yBAAyB;EACzB,uBAAuB;EACvB,eAAe;EACf,SAAS;EACT,QAAQ;AACV;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,QAAQ;IACR,UAAU;EACZ;AACF","sourcesContent":[".labelingApp {\n  text-align: center;\n  margin-top:20px;\n}\n\n.logout-container{\n  position: fixed;\n  top:20px;\n  /* left: 93%; */\n  right: 3%;\n}\n\n.progress-bar-container {\n  margin: auto;\n  width: 35%;\n  display: inline-block;\n  transform: rotate(-90deg);\n  transform-origin: right;\n  position: fixed;\n  right: 2%;\n  top: 15%;\n}\n\n@media screen and (max-width: 768px) and (min-width: 513px) {\n  .progress-bar-container{\n    width: 60%;\n  }\n}\n\n@media screen and (max-width: 512px){\n  .progress-bar-container{\n    top: 10%;\n    width: 40%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
