import "./homePage.css"
import React from "react";
import { Container, FormControl, TextField, Button, FormLabel, Alert } from '@mui/material';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
      primary: {
        main: '#2d8',
        contrastText: '#fff'
      },
      secondary: {
        main: '#000',
        contrastText: '#fff'
      },
    },
});
theme = responsiveFontSizes(theme);

class HomePage extends React.Component {
    state = {
        user: undefined,
        password: undefined,
        validationMessage: undefined
    }

    handleValidation() {
        const { user, password } = this.state;
        let error = undefined;
        let formIsValid = true;
    
        //Name
        if (!user) {
          formIsValid = false;
          error = "Username cannot be empty";
        }
        if (!password) {
            formIsValid = false;
            error = "Password cannot be empty";
          }
    
        if (typeof user !== "undefined") {
          if (!user.match(/^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/)) {
            formIsValid = false;
            error = "Username should contain no spaces, only letters and numbers";
          }
        }

        //Password
        if (typeof password !== "undefined") {
            if (!password.match(/^\S+$/)) {
              formIsValid = false;
              error = "Password should contain only letters, numbers, special symbols.";
            }
        }
    
        this.setState({ validationMessage: error });
        return formIsValid;
      }
      

    render() {
        const { onLoggingIn, errorMessage } = this.props;
        const { user, password, validationMessage } = this.state;

        return (
            <div className='wrapper'>
                <Container className='container' maxWidth='md'>
                    <ThemeProvider theme={theme} >
                    <FormControl className='form' color='secondary'>
                        <FormLabel
                            sx = {{textAlign: 'center', fontSize: '20px'}}
                        >Log in to start labeling</FormLabel>
                        <TextField 
                            required
                            label="Username"
                            variant="standard"
                            className='form-input'
                            onChange={evt => this.setState({user: evt.target.value})}
                            margin='normal'
                            sx={{fontSize: "18px"}}
                            fullWidth
                        />
                        <TextField 
                            required
                            label="Password"
                            variant="standard"
                            className='form-input'
                            onChange={evt => this.setState({password: evt.target.value})}
                            margin='normal'
                            type="password"
                            sx={{fontSize: "18px"}}
                            fullWidth
                        />
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <></>}
                        {validationMessage ? <Alert severity="error">{validationMessage}</Alert> : <></>}
                        <Button 
                            variant="contained"
                            onClick={() => {
                                if(this.handleValidation()){
                                    onLoggingIn(user, password);
                                }
                            }}
                            className='form-button'
                            sx={{marginTop: "10px", fontSize: "18px"}}
                        >
                            Log In
                        </Button>   
                    </FormControl>
                    </ThemeProvider>
                </Container>
            </div>
        )
    }
};

export default HomePage;
