import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressModified({value, required}) {
    const percents = value / required * 100;
    return (
      <Box sx={{paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress 
            sx={{height: '1.1em', borderRadius: '0.8em'}}
            variant="determinate"
            value={percents < 100 ? percents : 100} 
          />
        </Box>
        <Box sx={{ 
          minWidth: 35,
          marginBottom: 3, 
         }}>
          <Typography 
            sx={{
              fontSize: '1.1em',
              writingMode: 'vertical-rl'
            }}
            variant="body2" 
            color="text.secondary"
          >{`${value}/${required}`}</Typography>
        </Box>
      </Box>
    );
}

export default function LinearProgressWithLabel({value, required}) {
  return (
      <Box sx={{ width: '100%' }}>
        <LinearProgressModified value={value} required={required} />
      </Box>
    );
}
  